import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: () => require(`./en.json`),
  es: () => require(`./es.json`),
};

export default new VueI18n({
  locale: "es",
  messages: {
    en: messages.en(),
    es: messages.es(),
  },
});
