<template>
  <div>
    <v-app-bar :clipped-left="true" app clipped-right color="background">
      <v-app-bar-nav-icon v-if="$store.state.authenticated" color="secondary" @click.stop="handleOpenSidebar" />

      <v-app-bar-title>
        <router-link to="/">
          <DynamicIcon icon="industriasDavidCloud" class="header-logo mt-2" />
        </router-link>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn icon v-if="$store.state.authenticated" class="mx-2 mb-2 hidden-xs-only" cols="auto">
        <v-icon class="primary--text">mdi-bell-outline</v-icon>
      </v-btn>

      <div>
        <language-select-vue class="primary--text mt-2 offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-6"></language-select-vue>
      </div>

      <div cols="auto" class="white--text hidden-xs-only">
        <span>{{ userData.username }}</span>
      </div>

      <div cols="auto" v-if="showSettingsSelect">
        <settings-select-vue :userId="userData.user_id" class="primary--text mt-2 mx-2"></settings-select-vue>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-if="$store.state.authenticated && !profileNav && !loadingMenu" v-model="drawer.model"
      :clipped="true" :floating="false" :mini-variant="false" :permanent="false" :temporary="false" app overflow
      class="secondaryLight">
      <v-list nav class="secondaryLight" dense>
        <template v-for="section in routes">
          <v-list-group v-if="'children' in section" :key="section.name" color="black">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="black">{{ section.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ $t(section.name.replace('Menu', '').toLowerCase())
                  }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <menu-item class="pl-5 font-weight-bold" v-for="child in notHidden(section.name, section.children)"
              :key="child.name" :section="section" :child="child"></menu-item>
          </v-list-group>
          <v-list-item v-else :key="section.name + 'child'" :to="section.path">
            <v-list-item-icon>
              <v-icon color="black">{{ section.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{ $t(section.name.replace('Menu', '').toLowerCase())
                }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="section.name + 'divider'" />
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="$store.state.authenticated && profileNav" :clipped="true" :floating="false"
      :mini-variant="false" :permanent="false" :temporary="false" app overflow class="secondaryLight">
      <v-list nav>
        <v-list-item-group color="background">
          <template v-for="section in profileRoutes">
            <v-list-item :key="section.name + '-item'" :to="section.path.replace(':id', userData.user_id)">
              <v-list-item-icon v-if="!section.iconSvg">
                <v-icon color="background">
                  {{ section.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-bold text-start">{{ $t(section.meta.title.toLowerCase())
                }}</v-list-item-title>
            </v-list-item>
            <v-divider :key="section.name + '-divider'" />
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// @ is an alias to /src
import { pages } from "@/router/index";
import DynamicIcon from "./DynamicIcon.vue";
import { getMenuPermissions } from "@/api/permissions.js";
import { getUrls } from "@/api/urls.js"
import MenuItem from './MenuItem.vue';
import LanguageSelectVue from './LanguageSelect.vue';
import SettingsSelectVue from './SettingsSelect.vue'

export default {
  name: "header-item",
  components: {
    DynamicIcon,
    MenuItem,
    LanguageSelectVue,
    SettingsSelectVue
  },
  props: {
    showSettingsSelect: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data: () => ({
    drawer: {
      model: true,
    },
    loadingMenu: true,
    routes: [],
    urls: [],
    menu_permission: {},
    userData: null,
    profileNav: false,
    profileRoutes: []
  }),
  watch: {
    '$route'() {
      this.checkProfileNav()
    }
  },
  created() {
    this.userData = JSON.parse(window.sessionStorage.getItem('vuex'));
    if (this.$store.state.authenticated) {
      this.getRoutesPermission();
    }
  },
  mounted() {
    this.checkProfileNav();
  },
  methods: {
    handleOpenSidebar() {
      this.drawer.model = !this.drawer.model;
      return this.drawer.model;
    },
    notHidden: function (sectionName, childrenList) {
      const sectionModules = this.menu_permission[sectionName];
      if (sectionModules.includes('Roles') && this.userData.role !== 'admin') {
        let indexRoles = sectionModules.indexOf('Roles');
        if (indexRoles !== -1) {
          sectionModules.splice(indexRoles, 1);
        }
      }
      return (
        !childrenList ||
        childrenList.filter(function (item) {
          return !item.hidden && (sectionModules.includes(item.name) || sectionName == 'UrlsMenu');
        })
      );
    },
    getRoutesPermission() {
      let navigation = [];
      getMenuPermissions(this.$store.state.role)
        .then((response) => {
          navigation = response.menu_permission;
          this.menu_permission = navigation;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.getRoutes(navigation);
        });
    },
    getRoutes(navigation) {
      let navigationSections = Object.keys(navigation);

      if (navigationSections.length == 0) {
        navigationSections = ["Escritorio"];
      }
      let navigationRoutes = [];
      let profileNavigationRoutes = [];
      for (let i in pages) {
        if (navigationSections.includes(pages[i].name)) {
          navigationRoutes.push(pages[i]);
        }
        if (pages[i].name.includes('Perfil')) {
          profileNavigationRoutes.push(pages[i]);
        }
      }
      this.routes = navigationRoutes;
      this.profileRoutes = profileNavigationRoutes
      if (this.routes.some(item => item.name === 'UrlsMenu'))
        this.refreshUrls()
      this.loadingMenu = false
    },
    getSvg(iconPath) {
      return require("@/assets/" + iconPath);
    },
    refreshUrls() {
      getUrls()
        .then((result) => {
          this.urls = result.urls;
          this.routes.find(menuItem => menuItem.name === 'UrlsMenu')['children'].push(...this.urls)
        })
        .catch((err) => {
          console.log(err);
        })
    },
    checkProfileNav() {
      const currentUrl = this.$route.path;
      this.profileNav = currentUrl.includes('perfil');
    }
  },
};
</script>
<style>
nav {
  top: 70px !important;
  z-index: 100 !important;
  max-height: calc(100% - 92px) !important;
}

.v-list-item--disabled {
  color: black !important;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.text-name-user {
  flex-shrink: 1;
  flex-grow: 0;
  padding-bottom: 15px;
  color: white;
}

.flex-container {
  display: flex;
  align-items: center;
}

.btn-settings {
  flex-shrink: 1;
  flex-grow: 0;
  padding-bottom: 15px;
}

.header-logo {
  max-height: 80%;
  max-width: 14em;
  transition: scale-transition;
}

.v-list-item__icon {
  margin-right: 10px !important;
}

.mh-100 {
  max-height: 100%;
}
</style>
